.codex-editor {
    margin-top: 8px;
    max-height: 350px;
    overflow: scroll;
    padding: 8px 16px;
    padding-left: 42px;
    border-radius: 5px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.ce-block__content {
    max-width: calc(100% - 32px);
}

.ce-toolbar__actions {
    left: -12px;
}

.ce-toolbar__content {
    max-width: calc(100% - 32px);
    left: 0;
}