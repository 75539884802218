@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
:root {
  --gray: #2c2c2c;
}

.h1 .normal {
  font-weight: 400;
}

td {
  padding: 12px;
  min-width: 100px;
  width: auto;
}

td.wide {
  min-width: 250px;
  font-size: .875em;
}

td.small,
th.small {
  max-width: 50px;
}

td:not(.wide) {
  min-width: 150px;
}

thead {
  font-family: "Archivo", sans-serif;
}

.table > :not(caption) > * > * {
  padding: 10px 12px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom: inherit;
}

tr.align-items-center * {
  vertical-align: middle;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.btn-sm {
  padding: 6px 8px !important;
}

.btn-primary,
.btn-primary,
.btn-primary-secondary,
.btn-light {
  font-family: "Archivo", sans-serif;
}

.btn-container {
  width: 103.2% !important;
}


.btn-primary {
  transition: all 0.25s;
  text-transform: uppercase;
  font-weight: 800;
}

.btn-primary:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.bg-black {
  background-color: #000;
}

#landing .form-control {
  margin-top: 12px;
  background-color: #2b2b2b;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0);
}

#landing .form-control:focus {
  margin-top: 12px;
  background-color: #2b2b2b;
  color: #fff !important;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 300px;
}

.card {
  padding: 24px;
  border-radius: 0px;
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.no-scale:hover {
  -webkit-transform: none !important;
          transform: none !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

.btn-primary {
  text-transform: uppercase;
  font-weight: 800;
}

.btn-secondary {
  background-color: #000;
  text-transform: uppercase;
}

.btn-primary:focus {
  background-color: #000;
  box-shadow: none;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #000;
  border-color: #000;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.about-vibe {
  min-height: 500px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.side-menu-list {
  display: block;
  list-style-type: none;
  padding: 0px;
}

.collapse-menu {
  padding-left: 0px;
  list-style-type: none;
}

.collapse-menu .nav-item {
  margin: 0px;
  color: #000;
}

.nav-item {
  padding: 8px 2px;
  transition: all 0.25s;
  margin: 0px;
  color: #fff;
}

.footer {
  z-index: 0;
  margin-bottom: -10px;
}

.nav-link {
  width: 100%;
  display: block;
}

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-video-card {
  cursor: pointer;
  min-height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}

.text-left {
  text-align: left !important;
}

.sidebar {
  width: 160px;
  padding: 0px;
}

nav .side-menu {
  padding-right: 0px !important;
}

.pointer {
  cursor: pointer;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0px;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
  padding-right: 0px !important;
}

.main-panel {
  background: #fff;
  min-height: 100vh;
  padding: 12px;
  margin-left: 160px;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding-bottom: 2rem;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.profile-image {
  max-height: 250px;
  margin: auto;
  object-fit: contain;
  padding: 12px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.logo-thumbnail {
  width: 75px;
}

.thumbnail {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.hover-light:hover {
  background-color: #f8f9fa;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 400px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.dropdown-menu {
  margin-left: -45px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.home-video {
  height: 70vh;
  padding-right: 0px;
  padding-left: 0px;
  z-index: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#video {
  height: 70vh;
}

.logo-navbar {
  max-width: 75px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 10;
  transition: all 0.25s;
  height: 120vh;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.br-10 {
  border-radius: 10px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.show-mobile {
  display: none;
}

.main-cta-cont {
  padding-top: 20vh;
  z-index: 10;
}

.main-cta-cont h1 {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-gray {
  background-color: #2c2c2c;
  background-color: var(--gray);
}

.bg-verde {
  background-color: #c7e1c5;
}

.bg-vertical {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

#main-cta {
  max-width: 300px;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  .profile-image {
    max-height: 150px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .show-mobile {
    display: inline;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .h-80 {
    height: auto;
  }
  .navbar-container {
    padding-right: 24px;
    padding-top: 24px;
  }
  .mobile-center  {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #video {
    margin-top: -32px;
  }
}

.lesson-media {
    text-decoration: none;
    position: relative;
    max-height: 300px;    
    height: 100%;
    color: #000;
}

.lesson-media h4 {
    text-overflow: ellipsis;
    max-height: 29px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.lesson-media-thumbnail {
    max-width: 100px;
}

.lesson-media-content {
    text-align: center;
    flex-shrink: 0;
    display: flex;
    height: 245px;
    width: 100%;
}

.lesson-media-buttons {
    text-align: right;   
    width: 100%;
    height: 55px;
}

.lesson-media img {
    height: 100%;
    object-fit: cover;
}

.lesson-media  iframe {
    padding-left: 0px;
    padding-right: 0px;
    max-height: 100%;
    object-fit: contain;
}

.lesson-data {
    max-height: 500px;
    overflow: hidden;
}

.lesson-data img {
    max-height: 300px;
    object-fit: contain;
}
.codex-editor {
    margin-top: 8px;
    max-height: 350px;
    overflow: scroll;
    padding: 8px 16px;
    padding-left: 42px;
    border-radius: 5px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.ce-block__content {
    max-width: calc(100% - 32px);
}

.ce-toolbar__actions {
    left: -12px;
}

.ce-toolbar__content {
    max-width: calc(100% - 32px);
    left: 0;
}
:root {
    --blue: #3e80a4;
    --dark: #27272b;
    --primary: #C9E881;
    --accent: #D1B6E4;
    --success: #8FC040;
}

.border-primary {
    border: 2px solid #C9E881 !important;
    border: 2px solid var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.nav-link {
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-item:hover, .navbar-dark .navbar-nav .nav-item:hover *:not(.dropdown-item) {
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-item:hover {
    border-bottom: 4px solid #C9E881;
    border-bottom: 4px solid var(--primary);
}

.hover-blue:hover {
    cursor: pointer;
    color: white !important;
    background-color: #3e80a4 !important;
    background-color: var(--blue) !important;
}

.text-accent {
    color: #D1B6E4 !important;
    color: var(--accent) !important;
}

.text-primary {
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.bg-blue {
    background-color: #3e80a4 !important;
    background-color: var(--blue) !important;
}

.bg-primary {
    background-color: #C9E881 !important;
    background-color: var(--primary) !important;
}

.bg-accent {
    background-color: #D1B6E4 !important;
    background-color: var(--accent) !important;
}

.btn-outline-primary {
    border-color: #C9E881 !important;
    border-color: var(--primary) !important;
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.btn-primary {
    background-color: #C9E881;
    background-color: var(--primary);
    border-color: #C9E881;
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: #27272b;
    color: var(--dark);
}
.btn-primary:disabled {
    background-color: #C9E881;
    background-color: var(--primary);
    border-color: #C9E881;
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: #27272b;
    color: var(--dark);
}

.btn-accent {
    background-color: #D1B6E4;
    background-color: var(--accent);
    text-transform: uppercase;
    color: white;
}

.btn-dark:hover {
    color: #C9E881;
    color: var(--primary)
}

.btn-accent:hover {
    color: #D1B6E4;
    color: var(--accent);
    background-color: #27272b;
    background-color: var(--dark);
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:focus-within {
    background-color: #27272b;
    background-color: var(--dark);
    color: #C9E881;
    color: var(--primary);
    box-shadow: none !important;
    border: none !important;
}

.btn-outline-accent {
    border: 2px solid #D1B6E4 !important;
    border: 2px solid var(--accent) !important;
    color: #D1B6E4;
    color: var(--accent);
}

.btn-outline-accent:hover, .btn-outline-accent:focus, .btn-outline-accent:active {
    background-color: #D1B6E4;
    background-color: var(--accent);
    color: white;
}

.btn-outline-blue {
    border: 2px solid #3e80a4 !important;
    border: 2px solid var(--blue) !important;
    color: #3e80a4;
    color: var(--blue);
}

.check-complete {
    background-color: #8FC040;
    background-color: var(--success);
}

.media-lesson:hover {
    border: 2px solid #C9E881;
    border: 2px solid var(--primary);
}

.accordion-button {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: white !important;
    border-bottom: none;
    font-size: 1.2rem;
    line-height: 1.5em;
}

.accordion-button:not(.collapsed), .accordion-button:not(.collapsed) * {
    border-left-color: #D1B6E4 !important;
    border-left-color: var(--accent) !important;
    background-color: transparent;
    color: #D1B6E4 !important;
    color: var(--accent) !important;
    font-weight: bold;
    box-shadow: none;
    
}

.accordion-button:focus, .accordion-button:active, .accordion-button:focus-within {
    box-shadow: none;
}

.accordion-body {
    border-left: 5px solid #D1B6E4;
    border-left: 5px solid var(--accent);
}
@font-face {
    font-family: "Francois One";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/FrancoisOne-Regular.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 800;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-condensed-extra-bold.woff") format("woff")
}


body, span, li, div, p, .font-body, .accordion-button {
    font-family: "Poppins", sans-serif !important;
}

.font-open-sans {
    font-family: "Open Sans", sans-serif !important;
}

.font-style-italic {
    font-style: italic !important;
}

.futura {
    font-family: "Futura", sans-serif !important;
}

.francois {
    font-family: "Francois One", sans-serif !important;
}

h1, h2, .font-header {
    font-family: "Francois One", sans-serif !important;
    font-weight: bold;
    line-height: 1.8em;
}

h3, h4, h5, h6, .font-header-secondary {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
    text-transform: uppercase;
}
