.lesson-media {
    text-decoration: none;
    position: relative;
    max-height: 300px;    
    height: 100%;
    color: #000;
}

.lesson-media h4 {
    text-overflow: ellipsis;
    max-height: 29px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.lesson-media-thumbnail {
    max-width: 100px;
}

.lesson-media-content {
    text-align: center;
    flex-shrink: 0;
    display: flex;
    height: 245px;
    width: 100%;
}

.lesson-media-buttons {
    text-align: right;   
    width: 100%;
    height: 55px;
}

.lesson-media img {
    height: 100%;
    object-fit: cover;
}

.lesson-media  iframe {
    padding-left: 0px;
    padding-right: 0px;
    max-height: 100%;
    object-fit: contain;
}

.lesson-data {
    max-height: 500px;
    overflow: hidden;
}

.lesson-data img {
    max-height: 300px;
    object-fit: contain;
}